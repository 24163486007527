import errorMessage from '../textContent/errorMessage';
import showAlert from './alerts';

const resetPasswordUsingCode = async ({
  token,
  password,
  passwordConfirm,
  language,
}) => {
  try {
    const res = await axios.patch(
      '/api/v1/users/reset-password',
      {
        token,
        password,
        passwordConfirm,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      },
    );
    if (res.data.status === 'success')
      location.assign(`/verification`);
  } catch (err) {
    const message =
      err.response.data.message ||
      errorMessage(language, 'prodError1');
    showAlert(message);
  }
};

export default resetPasswordUsingCode;
